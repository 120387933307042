import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, SpeakerWaveIcon, BookOpenIcon, ClockIcon, DocumentTextIcon, PhotoIcon, QuestionMarkCircleIcon, TableCellsIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import useMixpanel from '../hooks/useMixpanel';

const MockTestDescription = () => {
  useMixpanel();
  const navigate = useNavigate();
  const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/mocktest-description.mp3`));
  const [showTips, setShowTips] = useState(false);

  const handleStartTest = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
    navigate('/mocktest-mic-test');
  };

  React.useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().catch((err) => console.error('Audio failed to play:', err));
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const parts = [
    {
      icon: DocumentTextIcon,
      title: 'Part 1: 지문 따라읽기',
      description: '주어진 문단을 읽는 문제입니다.',
      tips: [
        '준비시간 동안 전체 지문을 빠르게 읽어보고 잘 안읽히는 단어를 연습해보세요',
        '자연스러운 억양도 발음을 정확히 하는 것만큼 중요합니다',
        '구두점을 주의 깊게 보며 적절한 곳에서 끊어 읽으세요'
      ]
    },
    {
      icon: PhotoIcon,
      title: 'Part 2: 사진 묘사하기',
      description: '제시된 사진을 묘사하는 문제입니다.',
      tips: [
        '사진의 핵심적인 부분부터 전체적인 상황순으로 묘사해보세요',
        '준비시간 동안 답변에 포함될 수 있는 명사와 동사를 간단히 노트테이킹 해보세요',
      ]
    },
    {
      icon: QuestionMarkCircleIcon,
      title: 'Part 3: 질문에 답하기',
      description: '특정 주제에 관한 인터뷰에 응하는 문제입니다.',
      tips: [
        '질문의 의문사와 키워드를 주의 깊게 듣고 질문에 대한 관련성 높은 답을 해야합니다',
        '가능한 한 완전한 문장으로 답변하세요'
      ]
    },
    {
      icon: TableCellsIcon,
      title: 'Part 4: 표 정보 활용하여 답하기',
      description: '제시된 정보를 참고하여 문제에 답하는 문제입니다.',
      tips: [
        '표의 제목과 항목을 빠르게 파악하세요',
        '질문과 관련된 정보를 정확히 찾아 답변하세요',
        '답변 시 표의 구체적인 수치나 정보를 활용하세요'
      ]
    },
    {
      icon: ChatBubbleBottomCenterTextIcon,
      title: 'Part 5: 의견 표현하기',
      description: '주어진 주제에 대해 자신의 의견을 말하는 문제입니다.',
      tips: [
        '명확한 입장을 먼저 밝히세요',
        '2-3가지 구체적인 근거를 들어 설명하세요',
        '개인적인 경험이나 예시를 활용하면 더 효과적입니다'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>

      <div className="w-full max-w-4xl">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">
            배토스 토익 스피킹 1회 모의고사 안내
          </h1>
          <div className="inline-flex items-center justify-center space-x-2 bg-yellow-50 px-4 py-2 rounded-lg border border-yellow-200">
            <ClockIcon className="h-5 w-5 text-yellow-600" />
            <p className="text-yellow-800">
              모의고사는 모바일에 최적화 되어있지 않습니다. 데스크탑 브라우저에서 응시해주세요.
            </p>
          </div>
        </div>

        <div className="bg-white p-8 rounded-2xl shadow-lg border border-blue-100 mb-8">
          <div className="flex items-start space-x-4 mb-6">
            <SpeakerWaveIcon className="h-6 w-6 text-blue-600 mt-1" />
            <p className="text-lg text-blue-900">
              각 파트별 설명과 질문을 듣기 위해 볼륨을 켜주세요 📢
            </p>
          </div>

          <div className="space-y-4">
            <div className="flex items-start space-x-4">
              <ClockIcon className="h-6 w-6 text-blue-600 mt-1" />
              <p className="text-lg text-gray-700">
                모의고사는 약 15분 정도 소요되며, 크롬 브라우저에서 데스크탑을 사용하여 응시하는 것을 권장합니다.
              </p>
            </div>

            <div className="bg-red-50 p-4 rounded-lg border border-red-200">
              <p className="text-lg text-red-800">
                인터넷이 잘 되는 조용한 환경에서 음성을 인식할 수 있도록 준비해주세요. <strong>시험 도중에는 멈출 수 없습니다.</strong>
              </p>
            </div>
          </div>
        </div>

        <button
          className="w-full flex items-center justify-center space-x-2 px-6 py-4 bg-gray-50 text-gray-700 rounded-xl hover:bg-gray-100 transition-all mb-8 shadow-md border border-gray-200"
          onClick={() => setShowTips(!showTips)}
        >
          <BookOpenIcon className="h-6 w-6" />
          <span className="text-lg font-medium">Tips & Part별 설명 {showTips ? '접기' : '펼치기'}</span>
        </button>

        {showTips && (
          <div className="bg-white p-8 rounded-2xl shadow-lg border border-blue-100 space-y-8">
            {parts.map((part, index) => (
              <div key={index} className="p-6 bg-gray-50 rounded-xl hover:bg-blue-50 transition-colors">
                <div className="flex items-center space-x-3 mb-4">
                  <part.icon className="h-6 w-6 text-blue-600" />
                  <h3 className="text-xl font-bold text-blue-900">{part.title}</h3>
                </div>
                <p className="text-gray-700 mb-4">{part.description}</p>
                <div className="bg-white rounded-lg p-4">
                  <p className="font-medium text-blue-800 mb-2">준비 팁:</p>
                  <ul className="list-disc list-inside space-y-2 text-gray-700">
                    {part.tips.map((tip, tipIndex) => (
                      <li key={tipIndex}>{tip}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        )}

        <button
          className="w-full mt-8 px-8 py-4 bg-[#3488fe] text-white rounded-xl hover:bg-blue-600 transition-all transform hover:scale-105 shadow-md text-lg font-medium"
          onClick={handleStartTest}
        >
          모의고사 1회 시작하기
        </button>
      </div>
    </div>
  );
};

export default MockTestDescription;