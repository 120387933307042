import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useMixpanel from '../hooks/useMixpanel';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part3Description = () => {
  useMixpanel();
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  // Commented out audio functionality
  // const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part3-practice-question.mp3`));

  // Start the practice and stop the audio
  const startPractice = () => {
    // Commented out audio stop
    // if (audioRef.current) {
    //   audioRef.current.pause();
    // }
    navigate('/practice-part3');  // Navigate to Part 3 practice page
  };

  // Track page load
  React.useEffect(() => {
    mixpanel.track('Part 3 Description Page Loaded');
    
    // Commented out audio play
    // if (audioRef.current) {
    //   audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    // }
    
    // Commented out audio cleanup
    // return () => {
    //   if (audioRef.current) {
    //     audioRef.current.pause();
    //     audioRef.current.currentTime = 0;
    //   }
    // };
  }, []);

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}  // Navigate to the home page
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>
      
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 3: 질문에 답하기
      </h1>
  
      {/* Description */}
      <h2 className="text-lg mb-8 w-full max-w-4xl mx-auto">
        총 3개의 연관된 질문에 답변하는 문제입니다. 실전에선 3초, 해당 연습에선 30초의 준비 시간이 주어지며,
        15초 동안 답변하시면 됩니다.
      </h2>
  
      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-blue-50 text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        파트3 고득점 팁 및 예시 답변
      </button>
  
      {/* Tips Section */}
      {showTips && (
        <div className="w-full max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg border border-[#3488fe]">
            <div className="space-y-6">
              {/* Overview */}
              {/* <div>
                <h3 className="font-semibold text-lg mb-3">시험 개요</h3>
                <ul className="space-y-2">
                  <li>• 평가 항목: 질문 이해력, 답변의 논리성, 문법의 정확성</li>
                  <li>• 준비 시간: 30초 (실전: 3초)</li>
                  <li>• 답변 시간: 15초</li>
                </ul>
              </div> */}
  
              {/* Answer Structure */}
              <div>
                <h3 className="font-semibold text-lg mb-3">답변 구성</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-medium mb-2">1. 핵심 답변</p>
                    <p className="text-gray-700">질문의 <span className="underline decoration-blue-500">핵심 단어</span>를 활용하여 명확한 답변 제시</p>
                    <p className="text-gray-600 text-sm mt-1">
                      질문: "Do you <span className="text-blue-600 font-medium">prefer</span> studying in the <span className="text-blue-600 font-medium">morning</span> or at <span className="text-blue-600 font-medium">night</span>?"
                    </p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "I <span className="underline decoration-blue-500">prefer</span> studying in the <span className="underline decoration-blue-500">morning</span> because..."
                    </p>
                  </div>
  
                  <div>
                    <p className="font-medium mb-2">2. 부연 설명</p>
                    <p className="text-gray-700"><span className="bg-yellow-100">구체적인 이유</span>나 <span className="bg-yellow-100">예시</span> 추가</p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "I can <span className="text-blue-600 font-medium">focus better</span> and my mind is <span className="text-blue-600 font-medium">fresh</span> after a good night's sleep."
                    </p>
                  </div>
  
                  <div>
                    <p className="font-medium mb-2">3. 마무리</p>
                    <p className="text-gray-700">추가 의견이나 결론 제시 (시간 여유가 있을 경우)</p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "<span className="text-blue-600 font-medium">That's why</span> morning study works best for me."
                    </p>
                  </div>
                </div>
              </div>
  
              {/* Tips */}
              <div>
                <h3 className="font-semibold text-lg mb-3">준비 전략</h3>
                <ul className="space-y-2">
                  <li>• 질문의 <span className="bg-yellow-100">의문사(What, Why, How 등)</span>와 <span className="bg-yellow-100">시제</span>를 정확히 파악하세요</li>
                  <li>• 질문의 <span className="underline decoration-blue-500">문장 구조를 활용</span>하여 답변을 구성하세요</li>
                  <li>• 짧더라도 <span className="text-blue-600 font-medium">완성된 문장</span>으로 답변하세요</li>
                  <li>• Why 질문에는 반드시 <span className="bg-yellow-100">이유</span>를 포함해주세요</li>
                </ul>
              </div>
  
              {/* Example */}
              <div>
                <h3 className="font-semibold text-lg mb-3">답변 예시</h3>
                <div className="space-y-4">
                  
                  
                  <div>
                    <p className="font-medium">Q: <span className="text-blue-600">How often</span> do you go out with friends?</p>
                    <p className="text-gray-700 mt-2">
                      I <span className="underline decoration-blue-500">usually</span> go out with friends <span className="underline decoration-blue-500">once a week</span>. We usually go to a coffee shop or a restaurant.
                    </p>
                  </div>

                  <div>
                    <p className="font-medium">Q: <span className="text-blue-600">What</span> is your favorite season and <span className="text-blue-600">why</span>?</p>
                    <p className="text-gray-700 mt-2">
                      My favorite season is <span className="underline decoration-blue-500">winter</span> <span className="text-blue-600 font-medium">because</span> I enjoy the cold weather. 
                      I especially like drinking hot chocolate and wearing warm clothes during this time.
                      It's also the perfect season for indoor activities with family.
                    </p>
                  </div>
                </div>
              </div>
  
              {/* Common Expressions */}
              {/* <div>
                <h3 className="font-semibold text-lg mb-3">유용한 표현</h3>
                <ul className="space-y-2">
                  <li>• "In my opinion..." (제 생각에는...)</li>
                  <li>• "The main reason is..." (주된 이유는...)</li>
                  <li>• "For example..." (예를 들면...)</li>
                  <li>• "That's why..." (그래서...)</li>
                  <li>• "It depends on..." (~에 따라 다릅니다)</li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      )}
  
      {/* Button to start practice */}
      <button
        className="px-6 py-3 rounded-lg bg-[#3488fe] text-lg text-white mt-8 hover:bg-blue-600 transition-colors"
        onClick={startPractice}
      >
        연습 시작하기
      </button>
    </div>
  );
};

export default Part3Description;