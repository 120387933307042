import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useMixpanel from '../hooks/useMixpanel';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part4Description = () => {
  useMixpanel();
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  // Commented out audio functionality
  // const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part4-practice-question.mp3`));

  // Start the practice and stop the audio
  const startPractice = () => {
    // Commented out audio stop
    // if (audioRef.current) {
    //   audioRef.current.pause();
    // }
    navigate('/practice-part4');  // Navigate to Part 4 practice page
  };

  // Track page load
  React.useEffect(() => {
    mixpanel.track('Part 4 Description Page Loaded');
    
    // Commented out audio play
    // if (audioRef.current) {
    //   audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    // }
    
    // Commented out audio cleanup
    // return () => {
    //   if (audioRef.current) {
    //     audioRef.current.pause();
    //     audioRef.current.currentTime = 0;
    //   }
    // };
  }, []);

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}  // Navigate to the home page
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>
      
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 4: 정보를 활용한 질문 답변하기
      </h1>
  
      {/* Description */}
      <h2 className="text-lg mb-8 w-full max-w-4xl mx-auto">
        주어진 정보를 45초 동안 읽고 준비한 후, 3개의 연관된 질문에 답변하는 문제입니다.
        첫 두 질문은 15초, 마지막 질문은 30초 동안 답변하시면 됩니다.
      </h2>
  
      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-blue-50 text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        파트4 고득점 팁 및 예시 답변
      </button>
  
      {/* Tips Section */}
      {showTips && (
        <div className="w-full max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg border border-[#3488fe]">
            <div className="space-y-6">
              {/* Answer Structure */}
              <div>
                <h3 className="font-semibold text-lg mb-3">문제 유형</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-medium mb-2">1. 시간/장소 관련 질문</p>
                    <p className="text-gray-700">표의 <span className="bg-yellow-100">시간</span>과 <span className="bg-yellow-100">장소</span> 정보를 활용하여 답변</p>
                    <p className="text-gray-600 text-sm mt-1">
                      질문: "<span className="text-blue-600 font-medium">What time</span> does the meeting start and <span className="text-blue-600 font-medium">where</span> will it be held?"
                    </p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "The meeting <span className="underline decoration-blue-500">starts at 10 a.m.</span> in the <span className="underline decoration-blue-500">main conference room</span> on July 15th."
                    </p>
                  </div>

                  <div>
                    <p className="font-medium mb-2">2. 정보 확인 질문</p>
                    <p className="text-gray-700"><span className="bg-yellow-100">잘못된 정보</span>를 정정하거나 <span className="bg-yellow-100">변경된 내용</span> 전달</p>
                    <p className="text-gray-600 text-sm mt-1">
                      질문: "Can you <span className="text-blue-600 font-medium">confirm</span> if the cooking class is scheduled at 11 a.m.?"
                    </p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "I'm sorry, but that's <span className="underline decoration-blue-500">incorrect</span>. The class has been <span className="underline decoration-blue-500">rescheduled to 10 a.m.</span>"
                    </p>
                  </div>

                  <div>
                    <p className="font-medium mb-2">3. 상세 정보 나열</p>
                    <p className="text-gray-700">여러 <span className="bg-yellow-100">세부 사항</span>을 순서대로 설명</p>
                    <p className="text-gray-600 text-sm mt-1">
                      질문: "What <span className="text-blue-600 font-medium">events</span> are scheduled for the weekend?"
                    </p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "There are <span className="underline decoration-blue-500">two events</span>. First, a <span className="underline decoration-blue-500">marketing workshop</span> on Saturday at 2 p.m. Second, a <span className="underline decoration-blue-500">product seminar</span> on Sunday at 11 a.m."
                    </p>
                  </div>
                </div>
              </div>

              {/* Tips */}
              <div>
                <h3 className="font-semibold text-lg mb-3">준비 전략</h3>
                <ul className="space-y-2">
                  <li>• <span className="bg-yellow-100">45초</span> 준비 시간 동안 표의 <span className="underline decoration-blue-500">핵심 정보</span>를 빠르게 파악하세요</li>
                  <li>• 표에서 <span className="text-blue-600 font-medium">시간, 장소, 이벤트명</span>을 먼저 확인하세요</li>
                  <li>• 답변할 때는 <span className="bg-yellow-100">정확한 수치</span>와 <span className="bg-yellow-100">구체적인 명칭</span>을 사용하세요</li>
                </ul>
              </div>

              {/* Example */}
              <div>
                <h3 className="font-semibold text-lg mb-3">답변 예시</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-medium">Q: <span className="text-blue-600">When and where</span> is the team meeting?</p>
                    <p className="text-gray-700 mt-2">
                      The team meeting is scheduled for <span className="underline decoration-blue-500">9:30 a.m.</span> in the <span className="underline decoration-blue-500">main conference room</span>.
                    </p>
                  </div>

                  <div>
                    <p className="font-medium">Q: Could you <span className="text-blue-600">tell me more</span> about the afternoon events?</p>
                    <p className="text-gray-700 mt-2">
                      In the afternoon, we have <span className="underline decoration-blue-500">two sessions</span>. The <span className="text-blue-600 font-medium">first</span> is a presentation skills workshop at 2 p.m., and the <span className="text-blue-600 font-medium">second</span> is a team building activity at 4 p.m.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
  
      {/* Button to start practice */}
      <button
        className="px-6 py-3 rounded-lg bg-[#3488fe] text-lg text-white mt-8 hover:bg-blue-600 transition-colors"
        onClick={startPractice}
      >
        연습 시작하기
      </button>
    </div>
  );
};

export default Part4Description;