import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import useMixpanel from '../hooks/useMixpanel';

const Part2Description = () => {
  useMixpanel();
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  // Commented out audio functionality
  // const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part2-practice-prompt.mp3`));

  // Start the practice and stop the audio
  const startPractice = () => {
    // Commented out audio stop
    // if (audioRef.current) {
    //   audioRef.current.pause();
    // }
    navigate('/practice-part2');  // Navigate to Part 2 practice page
  };

  // Track page load
  React.useEffect(() => {
    mixpanel.track('Part 2 Description Page Loaded');

    // Commented out audio play
    // if (audioRef.current) {
    //   audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    // }
    
    // Commented out audio cleanup
    // return () => {
    //   if (audioRef.current) {
    //     audioRef.current.pause();
    //     audioRef.current.currentTime = 0;
    //   }
    // };
  }, []);

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-center p-4">
      {/* Back Icon at the top-left corner */}
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}  // Navigate to the home page
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>
      
      <h1 className="text-2xl font-bold text-center mb-8">
        Part 2: 사진 묘사하기
      </h1>

      {/* Description */}
      <h2 className="text-lg mb-8 w-full max-w-4xl mx-auto">
        주어진 사진을 보며 45초간 준비한 후, 30초 동안 사진에 대한 묘사를 하는 문제입니다.
      </h2>

      {/* Button to show/hide tips */}
      <button
        className="px-6 py-3 rounded-lg bg-blue-50 text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        파트2 고득점 팁 및 예시 답변
      </button>

      {/* Tips Section */}
      {showTips && (
        <div className="w-full max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg border border-[#3488fe]">
            <div className="space-y-6">
              {/* Overview */}
              {/* <div>
                <h3 className="font-semibold text-lg mb-3">시험 개요</h3>
                <ul className="space-y-2">
                  <li>• 평가 항목: 어휘력, 발음, 문법의 정확성</li>
                  <li>• 준비 시간: 45초</li>
                  <li>• 답변 시간: 30초</li>
                </ul>
              </div> */}

              {/* Answer Structure */}
              <div>
                <h3 className="font-semibold text-lg mb-3">답변 구성 템플릿</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-medium mb-2">1. 장소와 시간</p>
                    <p className="text-gray-700">
                      I think this picture was taken <span className="text-blue-600 font-medium">[in/at]</span> <span className="bg-yellow-100">[장소/상황]</span>
                    </p>
                  </div>

                  <div>
                    <p className="font-medium mb-2">2. 인물과 행동</p>
                    <p className="text-gray-700">
                      There are <span className="text-blue-600 font-medium">[총 인물 수/many]</span> people in this picture. 
                      <span className="bg-yellow-100">[인물]</span> is/are <span className="underline decoration-blue-500">[행동]</span>
                    </p>
                  </div>

                  <div>
                    <p className="font-medium mb-2">3. 추가적인 세부 사항</p>
                    <p className="text-gray-700">• 인물: The <span className="bg-yellow-100">[인물]</span> on the <span className="text-blue-600 font-medium">left/right</span> side of the picture is/are <span className="underline decoration-blue-500">wearing/holding</span> <span className="bg-yellow-100">[옷차림/소지품]</span></p>
                    <p className="text-gray-700">• 환경: In the background, there is/are <span className="bg-yellow-100">[주변 환경]</span></p>
                  </div>

                  {/* <div>
                    <p className="font-medium mb-2">4. 분위기/상황</p>
                    <p className="text-gray-700">The overall atmosphere seems [분위기/상황]</p>
                  </div> */}
                </div>
              </div>

              {/* Tips */}
              <div>
                <h3 className="font-semibold text-lg mb-3">준비 전략</h3>
                <ul className="space-y-2">
                  <li>• <span className="text-blue-600 font-medium">45초</span> 준비 시간 동안 사진의 <span className="bg-yellow-100">핵심 요소</span>들에 대한 <span className="underline decoration-blue-500">명사/동사</span> 위주로 메모하세요</li>
                  <li>• 사진에서 <span className="bg-yellow-100">눈에 띄는 사항</span>부터 <span className="bg-yellow-100">구체적인 세부 사항</span> 순으로 설명하세요</li>
                  <li>• 복잡하고 긴 문장보다는 <span className="text-blue-600 font-medium">간단하고 명료하게</span> 끊어서 말해보세요</li>
                </ul>
              </div>

              {/* Sample Answer */}
              <div>
                <h3 className="font-semibold text-lg mb-3">예시 답변</h3>
                <p className="text-gray-700">
                  This picture was taken in a <span className="underline decoration-blue-500">meeting room</span>. 
                  There are <span className="text-blue-600 font-medium">6 people</span> in the room. 
                  The <span className="bg-yellow-100">man with glasses</span> is <span className="underline decoration-blue-500">giving a presentation</span> in front of the whiteboard, 
                  and the others seem to be <span className="underline decoration-blue-500">engaged</span>. 
                  A <span className="bg-yellow-100">woman</span> on the <span className="text-blue-600 font-medium">left side</span> of the picture is <span className="underline decoration-blue-500">typing</span> something on her laptop. 
                  I also see lots of papers <span className="underline decoration-blue-500">scattered</span> on the table.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Button to start practice */}
      <button
        className="px-6 py-3 rounded-lg bg-[#3488fe] text-lg text-white mt-8 hover:bg-blue-600 transition-colors"
        onClick={startPractice}
      >
        연습 시작하기
      </button>
    </div>
  );
};

export default Part2Description;