import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';  // Import useAuth for user authentication
import RecordButton from '../RecordButton';  // A component for starting/stopping recording
import LoadingAlert from '../LoadingAlert';

const MockTestPart2Problems = ({ mocktestId }) => {
  const { user } = useAuth();  // Get user info from useAuth
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [timer, setTimer] = useState(45); // Timer for both preparation and answer time
  const [recording, setRecording] = useState(false);
  const navigate = useNavigate();
  const mediaRecorderRef = useRef(null); // Ref for the media recorder
  const streamRef = useRef(null); // Ref for the media stream
  const timerRef = useRef(null); // Ref for the timer
  const audioChunks = useRef([]); // To store the recorded audio chunks
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch mocktest questions for Part 2
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/mocktest${mocktestId}`)
      .then((response) => setQuestions(response.data.part2.questions))
      .catch((error) => console.error("Error fetching questions:", error));
  }, [mocktestId]);

  useEffect(() => {
    // Start the preparation countdown when a new question is displayed
    startPreparationCountdown();
  }, [currentQuestionIndex]);



  const startPreparationCountdown = () => {
    clearInterval(timerRef.current); // Clear any existing intervals to avoid multiple intervals running
    setTimer(45);  // Set preparation time to 45 seconds

    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current); // Clear the interval when the preparation time ends
          startRecording();  // Start recording once the preparation timer ends
        }
        return prev - 1;
      });
    }, 1000);  // Ensure the timer runs every 1 second
  };

  const startRecording = async () => {
    clearInterval(timerRef.current); // Clear the preparation timer
    setRecording(true);
    audioChunks.current = [];  // Clear any previous recordings

    // Start recording via microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);  // Collect the audio chunks
        }
      };

      mediaRecorderRef.current.start();
      console.log("Microphone recording started");
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }

    // Start the answer countdown
    setTimer(45); // Answer timer is also 45 seconds
    timerRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          stopRecording();  // Stop recording when the answer time ends
        }
        return prev - 1;
      });
    }, 1000);
  };

  const stopRecording = () => {
    setRecording(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop(); // Stop the media recorder
      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
      console.log("Microphone recording stopped");

      if (questions[currentQuestionIndex]) {
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
          const formData = new FormData();
          formData.append('mocktest_id', mocktestId);
          formData.append('user_id', user.user_id);
          formData.append('question_id', questions[currentQuestionIndex].question_id);
          formData.append('audio_file', audioBlob, `${questions[currentQuestionIndex].question_id}.wav`);
          formData.append('current_part', 2);  // For Part 2
          formData.append('reference_text', questions[currentQuestionIndex].text);  // The reference text (if needed)

          try {
            setTimer(0);
            clearInterval(timerRef.current);
            setIsLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/submit-answer`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log('Answer submitted successfully:', response.data);
            if (response) {
              setIsLoading(false);
              // Move to the next question or part
              if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);  // Move to the next question
              } else {
                navigate(`/mocktest${mocktestId}/part3-instruction`);  // Navigate to the next part instruction when all questions are done
              }
            }
          } catch (error) {
            console.error('Error submitting answer:', error);
          }
        };
      }
    }
  };


  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-start">
      {/* Display question_id at the top with gray background */}
    {/* Display question_id at the top with gray background */}
    {isLoading && (
        <LoadingAlert 
          message="답변을 전송 중입니다. 잠시만 기다려주세요."
        />
      )}
    {questions.length > 0 && (
      <h1 className="text-xl lg:text-2xl font-bold text-center mb-6 bg-gray-300 w-full py-4">
        {/* Remove leading zero using regex */}
        Questions {questions[currentQuestionIndex].question_id.split('_')[1].slice(1).replace(/^0+/, '')} of 11
      </h1>
    )}
  
      {questions.length > 0 && (
        <div className="bg-white rounded-lg p-6 lg:p-8 w-full max-w-3xl mb-6">
          <img
            src={`${process.env.REACT_APP_S3_BASE_URL}/images/${questions[currentQuestionIndex].text}`}
            alt={`Part 2 - Question ${currentQuestionIndex + 1}`}
            className="w-full max-w-md h-auto mb-6 mx-auto object-contain"  // Ensures the image fits well and is centered
          />
          <p className="text-base md:text-lg lg:text-xl text-gray-700 mb-6 text-center">
            {recording ? "Recording Time" : "Preparation Time"}: {timer} seconds
          </p>
          <div className="flex justify-center">
            <RecordButton recording={recording} onStart={startRecording} onStop={stopRecording} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MockTestPart2Problems;