import React, { useState, useEffect, useRef } from 'react';
import { IconPaths, TailwindStatics } from '../statics';
import { PlayIcon, PauseIcon } from '@heroicons/react/24/solid';

const CustomAudioPlayer = ({ audioBlob, audioUrl, duration, id }) => {
  const [audioURL, setAudioURL] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const audioId = `audio-player-${id}`;

  useEffect(() => {
    if (audioRef.current) {
      if (audioBlob) {
        audioRef.current.src = URL.createObjectURL(audioBlob);
      } else if (audioUrl) {
        audioRef.current.src = audioUrl;
      }
    }
  }, [audioBlob, audioUrl]);

  useEffect(() => {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      const onTimeUpdate = () => {
        setCurrentTime(audioElement.currentTime);
      };

      const handleEnded = () => {
        const audioElement = document.getElementById(audioId);
        setIsPlaying(false);
        audioElement.currentTime = 0;
      };

      audioElement.addEventListener('timeupdate', onTimeUpdate);
      audioElement.addEventListener('ended', handleEnded);

      return () => {
        audioElement.removeEventListener('timeupdate', onTimeUpdate);
        audioElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [audioURL]);

  const handlePlay = () => {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      audioElement.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    const audioElement = document.getElementById(audioId);
    if (audioElement) {
      audioElement.pause();
      setIsPlaying(false);
    }
  };

  const progress = currentTime <= duration ? (currentTime / duration) * 100 : 100;

  return (
    <div className={`flex flex-col items-center bg-transparent max-w-md w-full mt-5 mb-5 ${TailwindStatics.MINIMUM_WIDTH_100}`}>
      <audio id={audioId} ref={audioRef} preload="metadata"/>
      
      <div className="flex items-center bg-transparent w-full">
        <button
          onClick={isPlaying ? handlePause : handlePlay}
          className="relative z-10"
          style={{ cursor: 'pointer' }} 
        >
          {isPlaying ? (
            <PauseIcon className="h-8 w-8 text-gray-800" />
          ) : (
            <PlayIcon className="h-8 w-8 text-gray-800" />
          )}
        </button>
        
        <div className="flex-1 h-2 bg-gray-200 rounded ml-4 relative">
          <div
            className="h-full bg-black rounded"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
