import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { ContentContainerBox } from '../components/Container';
import CustomAudioPlayer from '../components/CustomAudioPlayer';
import { Doughnut } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import {ColorStatics, LoadingStatics} from '../statics';

Chart.register(...registerables);

const DoughnutChart = ({ score }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [score, 100 - score],
        backgroundColor: [ColorStatics.MAIN_GREEN, ColorStatics.MAIN_YELLOW],
      },
    ],
  });

  useEffect(() => {
    setChartData({
      labels: [],
      datasets: [
        {
          data: [score, 100 - score],
          backgroundColor: [ColorStatics.MAIN_GREEN, ColorStatics.MAIN_YELLOW],
          animation: {
            duration: 1000, 
            easing: 'easeOutBounce', 
          },
        },
      ],
    });
  }, [score]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: null, 
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="mt-5 mb-5 relative">
      <Doughnut data={chartData} options={options} />
      <div className="absolute inset-0 flex items-center justify-center">
        <span className="text-md font-sans font-semibold">{score}</span>
      </div>
    </div>
  );
};

function ResultWordAudioPlayer({ word }) {
    const [audioBlob, setAudioBlob] = useState(null);
    const [audioDuration, setAudioDuration] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
      async function fetchAudio() {
        try {
          // Using our backend endpoint with API_BASE_URL
          const response = await axios.post(`${API_BASE_URL}/api/google-tts`, {
            text: word
          });
          
          const base64AudioContent = response.data.audioContent;
          const audioBlob = base64ToBlob(base64AudioContent, 'audio/mp3');
          setAudioBlob(audioBlob);
  
          const audioDuration = await getDurationFromBlob(audioBlob);
          setAudioDuration(audioDuration);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching audio:', error);
          setError(true);
          setLoading(false);
        }
      }
      fetchAudio();
    }, [word]);
  
    function base64ToBlob(base64Data, contentType) {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    }
  
    function getDurationFromBlob(blob) {
      return new Promise((resolve) => {
        const audioURL = URL.createObjectURL(blob);
        const audio = new Audio(audioURL);
        audio.onloadedmetadata = () => {
          resolve(audio.duration);
          URL.revokeObjectURL(audioURL); 
        };
      });
    }
  
    if (loading) {
      return (
        <div className="flex flex-col items-center justify-center p-4">
          <p className="text-sm text-gray-600 text-center whitespace-pre-line">
            {LoadingStatics.AUDIO_LOADING_TEXT}
          </p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="text-sm text-red-500 text-center p-4 whitespace-pre-line">
          {LoadingStatics.AUDIO_ERROR_TEXT}
        </div>
      );
    }

    return (
      <CustomAudioPlayer audioBlob={audioBlob} duration={audioDuration} id={word}/>
    );
  }

const ResultWordList = ({ words }) => {
    const [selectedIdx, setSelectedIdx] = useState(null);
    const firstThreshold = 45;
    const secondThreshold = 75;
    const getColor = (accuracy) => {
      if (accuracy <= firstThreshold) {
        return "#FF7676";  // Warm red
      } else if (accuracy < secondThreshold) {
        return "#FFA726";  // Warm orange
      } 
    };
    const cursorStyle = (accuracyScore) => {
      return accuracyScore < secondThreshold ? 'pointer' : 'default';
    };
    const handleWordClick = async (wordObj) => {
        if (selectedIdx === wordObj.idx) {
          setSelectedIdx(null);
        } else {
          setSelectedIdx(wordObj.idx); 
        }
    };
  const handleClick = (wordObj) => {
    if (wordObj.accuracy_score <= secondThreshold) {
      handleWordClick(wordObj);
    }
  };
  const SpanWord = ({ 
    onClickFunc = () => {}, 
    score = 100, 
    marginRight = '2px', 
    text }
  ) => {
    return (
      <span
        onClick={onClickFunc} 
        style={{
          color: getColor(score),
          border: `1px dotted ${getColor(score)}`,
          marginRight: marginRight,
          padding: '2px',
          cursor: cursorStyle(score) 
        }}>
        {text}
      </span>
    );
  };
    return(
      <>
        <div className={`mb-2 text-center text-sm font-bold`}>
          <SpanWord 
              score={100} 
              marginRight='15px'
              text='Excellent'
            />
            <SpanWord 
              score={secondThreshold - 1} 
              marginRight='15px'
              text='Good'
            />
            <SpanWord 
              score={firstThreshold - 1} 
              marginRight='0px'
              text='Needs Improvement'
            />
        </div>
        <div className={`mb-10 text-center text-sm`}>
         단어를 클릭해서 정확한 발음을 들어보세요!
        </div>
        <div className="flex flex-wrap">    
          {words.map((wordObj) => (
            <div
              key={wordObj.idx}
              className="mr-2 mb-4 text-center font-sans font-light">
              <SpanWord 
                onClickFunc={() => handleClick(wordObj)} 
                score={wordObj.accuracy_score} 
                text={wordObj.word} 
              />
            {selectedIdx === wordObj.idx &&  (
              <div
                style={{
                   display: 'flex', 
                   flexDirection: 'column', 
                   alignItems: 'center',
                   width: 'fit-content'}}
                >
                  <div>
                    <ResultWordAudioPlayer word={wordObj.word} />
                  </div>
              </div>
             )}
            </div>
          ))}
        </div>
      </>
    )
};

const AnswerContainer = ({ 
  userRecordingAudioBlob, 
  userRecordingAudioUrl,
  userRecordingDuration, 
  correctAudioUrl,
  correctAudioDuration,
  azureAiScore,
  questionId,
  isMockTest = false
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState('myAnswer');

  // Updated function to handle both practice and mock test URLs
  const getCorrectAnswerUrl = (questionId) => {
    if (!questionId) {
      console.error('Invalid questionId:', questionId);
      return '';
    }
    
    // Convert to string and pad with leading zero if needed
    const numStr = String(questionId).padStart(2, '0');
    
    // If it's a mock test, use mock test URL format
    const url = isMockTest 
      ? `${process.env.REACT_APP_S3_BASE_URL}/recordings/part1_answers/mock1_${numStr}.mp3`
      : `${process.env.REACT_APP_S3_BASE_URL}/recordings/part1_answers/q${numStr}.mp3`;
    
    console.log('Part1Components - Generated URL:', url);
    console.log('Part1Components - Props:', { questionId, isMockTest, numStr });
    
    return url;
  };

  const handleSelect = (answerType) => {
    setSelectedAnswer(answerType);
  };

  return (
    <ContentContainerBox>
      <div className="flex items-center">
        <div
          onClick={() => handleSelect('myAnswer')}
          className={`p-4 rounded-lg cursor-pointer transition`}
          style={{
            backgroundColor: selectedAnswer === 'myAnswer' ? ColorStatics.MAIN_YELLOW : '#FFFFFF',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <p className="text-md font-sans font-semibold">My Answer</p>
          {selectedAnswer === 'myAnswer' && (
            <div className="absolute -top-1 -left-1 w-full h-full" />
          )}
        </div>

        <div
          onClick={() => handleSelect('correctAnswer')}
          className={`p-4 rounded-lg cursor-pointer transition`}
          style={{
            backgroundColor: selectedAnswer === 'correctAnswer' ? ColorStatics.MAIN_YELLOW : '#FFFFFF',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <p className="text-md font-sans font-semibold">Correct Answer</p>
          {selectedAnswer === 'correctAnswer' && (
            <div className="absolute -top-1 -left-1 w-full h-full" />
          )}
        </div>
      </div>

      {selectedAnswer === 'myAnswer' && (
        userRecordingAudioBlob ? (
          <CustomAudioPlayer audioBlob={userRecordingAudioBlob} duration={userRecordingDuration} id={"my-answer"} />
        ) : userRecordingAudioUrl && (
          <CustomAudioPlayer audioUrl={userRecordingAudioUrl} duration={userRecordingDuration} id={"my-answer"} />
        )
      )}

      {selectedAnswer === 'correctAnswer' && (
        <CustomAudioPlayer 
          audioUrl={correctAudioUrl || getCorrectAnswerUrl(questionId)}
          duration={correctAudioDuration} 
          id={"correct-answer"} 
        />
      )}

      <ResultWordList words={azureAiScore.words} />
    </ContentContainerBox>
  );
};

export {DoughnutChart, AnswerContainer};