import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios to make API calls
import { useAuth } from '../context/AuthProvider';
import { SparklesIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part2TemplatePractice = () => {
  const navigate = useNavigate();
  const { user } = useAuth(); // Get the authenticated user

  // State to hold progress for the location section
  const [locationProgress, setLocationProgress] = useState({ completed: 0, total: 0 });
  const [actionProgress, setActionProgress] = useState({ completed: 0, total: 0 });
  const [peopleProgress, setPeopleProgress] = useState({ completed: 0, total: 0 });
  const [surroundingProgress, setSurroundingProgress] = useState({ completed: 0, total: 0 });



  const sections = [
    { 
      title: '장소 관련',
      difficulty: '중하',
      difficultyColor: 'bg-green-100 text-green-800',
      path: '/part2-template-practice-location',
      id: 'location',
      icon: '🏢'
    },
    { 
      title: '동작 관련',
      difficulty: '중',
      difficultyColor: 'bg-yellow-100 text-yellow-800',
      path: '/part2-template-practice-action',
      id: 'action',
      icon: '🏃'
    },
    { 
      title: '인물 묘사 관련',
      difficulty: '중',
      difficultyColor: 'bg-yellow-100 text-yellow-800',
      path: '/part2-template-practice-people',
      id: 'people',
      icon: '👥'
    },
    { 
      title: '주변 묘사 관련',
      difficulty: '중상',
      difficultyColor: 'bg-orange-100 text-orange-800',
      path: '/part2-template-practice-surrounding',
      id: 'surrounding',
      icon: '🌆'
    },
    // { title: '마무리 표현', path: '/part2-template-practice-conclusion', id: 'conclusion' },
  ];

  useEffect(() => {
    // Fetch progress for the location section if user is authenticated
    const fetchLocationProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_location.json' }
          });
          setLocationProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching location progress:', error);
        }
      }
    };

    // Fetch progress for the action section if user is authenticated
    const fetchActionProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_action.json' }
          });
          setActionProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching action progress:', error);
        }
      }
    };

    // Fetch progress for the people section if user is authenticated
    const fetchPeopleProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_people.json' }
          });
          setPeopleProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching people progress:', error);
        }
      }
    };

    // Fetch progress for the surrounding section if user is authenticated
    const fetchSurroundingProgress = async () => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: 'part2_template_surrounding.json' }
          });
          setSurroundingProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error('Error fetching surrounding progress:', error);
        }
      }
    };

    fetchLocationProgress();
    fetchActionProgress();
    fetchPeopleProgress();
    fetchSurroundingProgress();
  }, [user]);


  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Navigation */}
      <div className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm border-b border-gray-100 z-10">
        <div className="max-w-7xl mx-auto px-4 h-16 flex items-center">
          <button
            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
            onClick={() => navigate('/')}
          >
            <ArrowLeftIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="pt-24 sm:pt-28 pb-12 px-4 sm:px-6 lg:px-8 max-w-5xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-8 sm:mb-24">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 tracking-tight">
            파트 2 빈출 표현 100
          </h1>
          <div className="inline-flex items-center justify-center space-x-2.5 bg-blue-50/80 px-5 py-3 rounded-2xl shadow-sm">
            <SparklesIcon className="h-5 w-5 text-blue-500" />
            <p className="text-sm sm:text-base text-gray-700 font-medium">
              실전에서 바로 활용할 수 있는 표현들을 직접 녹음하고 피드백 확인하며 반복적으로 연습해보세요
            </p>
          </div>
        </div>

        {/* Info Card */}
        {/* <div className="bg-white rounded-2xl border border-gray-200/75 p-6 sm:p-8 mb-8 sm:mb-10 shadow-sm hover:shadow-md transition-all duration-300">
          <p className="text-lg text-gray-700 mb-4 leading-relaxed">
            파트 2 빈출 표현 100가지를 직접 녹음하고 피드백을 확인하며 연습해주세요.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed flex flex-wrap items-center gap-2">
            실전에서 바로 나올 수 있도록 반복적으로 연습하고
            <span className="inline-block bg-yellow-100 px-3 py-1 rounded-lg font-medium text-yellow-800">
              새로운 단어 및 표현은 외워주세요
            </span>
          </p>
        </div> */}

        {/* Section Cards */}
        <div className="grid gap-4 sm:gap-5">
          {sections.map((section, index) => (
            <div 
              key={index} 
              className="bg-white rounded-2xl border border-gray-200/75 p-5 sm:p-7 shadow-sm hover:shadow-md transition-all duration-300"
            >
              <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6">
                <div className="flex-1 space-y-3">
                  <div className="flex items-center gap-3 flex-wrap">
                    <span className="text-2xl">{section.icon}</span>
                    <h3 className="text-xl font-semibold text-gray-900">{section.title}</h3>
                    <span className={`text-xs px-2.5 py-1 rounded-full font-medium ${section.difficultyColor}`}>
                      난이도: {section.difficulty}
                    </span>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="flex-1 h-2.5 bg-gray-100 rounded-full overflow-hidden">
                      <div 
                        className="h-full bg-blue-500 rounded-full transition-all duration-500 ease-out"
                        style={{ 
                          width: `${section.id === 'location' 
                            ? (locationProgress.completed / locationProgress.total) * 100
                            : section.id === 'action'
                            ? (actionProgress.completed / actionProgress.total) * 100
                            : section.id === 'people'
                            ? (peopleProgress.completed / peopleProgress.total) * 100
                            : section.id === 'surrounding'
                            ? (surroundingProgress.completed / surroundingProgress.total) * 100
                            : 0}%`
                        }}
                      />
                    </div>
                    <span className="text-sm text-gray-600 whitespace-nowrap">
                      {section.id === 'location' 
                        ? `${locationProgress.completed}/${locationProgress.total}`
                        : section.id === 'action'
                        ? `${actionProgress.completed}/${actionProgress.total}`
                        : section.id === 'people'
                        ? `${peopleProgress.completed}/${peopleProgress.total}`
                        : section.id === 'surrounding'
                        ? `${surroundingProgress.completed}/${surroundingProgress.total}`
                        : '0/20'} 문제
                    </span>
                  </div>
                </div>

                <button
                  className="w-full sm:w-auto px-6 py-3 text-base rounded-xl bg-blue-500 text-white font-medium hover:bg-blue-600 active:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow-sm hover:shadow"
                  onClick={() => navigate(section.path)}
                >
                  시작하기
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Part2TemplatePractice;