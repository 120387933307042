import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import useMixpanel from '../hooks/useMixpanel';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part5Description = () => {
  useMixpanel();
  const navigate = useNavigate();
  const [showTips, setShowTips] = useState(false);  // State to toggle tips visibility
  // Commented out audio functionality
  // const audioRef = useRef(new Audio(`${process.env.REACT_APP_S3_BASE_URL}/recordings/part5-question.mp3`));

  // Start the practice and stop the audio
  const startPractice = () => {
    // Commented out audio stop
    // if (audioRef.current) {
    //   audioRef.current.pause();
    // }
    navigate('/practice-part5');  // Navigate to the practice page
  };

  // Track page load
  React.useEffect(() => {
    mixpanel.track('Part 5 Description Page Loaded');
    
    // Commented out audio play
    // if (audioRef.current) {
    //   audioRef.current.play().catch(err => console.error('Audio failed to play:', err));
    // }
    
    // Commented out audio cleanup
    // return () => {
    //   if (audioRef.current) {
    //     audioRef.current.pause();
    //     audioRef.current.currentTime = 0;
    //   }
    // };
  }, []);

  return (
    <div className="min-h-screen bg-neutral-50 flex flex-col items-center justify-center p-4">
      <button
        className="absolute top-4 left-4 text-xl"
        onClick={() => navigate('/')}
      >
        <ArrowLeftIcon className="h-6 w-6 text-black" aria-hidden="true" />
      </button>

      <h1 className="text-2xl font-bold text-center mb-8">
        Part 5: 의견 제시하기
      </h1>

      <h2 className="text-lg mb-8 w-full max-w-4xl mx-auto">
        주어진 주제에 대한 자신의 의견을 제시하는 문제입니다. 45초 동안 준비한 후,
        60초 동안 답변하시면 됩니다.
      </h2>

      <button
        className="px-6 py-3 rounded-lg bg-blue-50 text-black text-lg w-full max-w-4xl mx-auto mb-4"
        onClick={() => setShowTips(!showTips)}
      >
        파트5 고득점 팁 및 예시 답변
      </button>

      {showTips && (
        <div className="w-full max-w-4xl mx-auto">
          <div className="bg-white p-6 rounded-lg border border-[#3488fe]">
            <div className="space-y-6">
              {/* Answer Structure */}
              <div>
                <h3 className="font-semibold text-lg mb-3">답변 구성</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-medium mb-2">1. 의견 제시</p>
                    <p className="text-gray-700">주어진 주제에 대한 <span className="bg-yellow-100">(찬성/반대) 의견</span>  명확히 표현</p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "I <span className="underline decoration-blue-500">agree/disagree</span> with the statement that <span className="text-blue-600 font-medium">[주어진 주제]</span>"
                    </p>
                  </div>

                  <div>
                    <p className="font-medium mb-2">2. 이유 언급</p>
                    <p className="text-gray-700"><span className="bg-yellow-100">논리적인 근거</span> 제시</p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "I have <span className="underline decoration-blue-500">two reasons</span> to support my opinion."
                    </p>
                  </div>

                  <div>
                    <p className="font-medium mb-2">3. 상세 설명</p>
                    <p className="text-gray-700"><span className="bg-yellow-100">구체적 이유</span> 제시와 <span className="bg-yellow-100">개인적 경험 혹은 예시로 </span> 뒷받침</p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "<span className="text-blue-600 font-medium">First</span>, [첫 번째 이유]. <span className="text-blue-600 font-medium">Second</span>, [두 번째 이유]. 
                      <span className="underline decoration-blue-500">For example</span>, when I was..."
                    </p>
                  </div>

                  <div>
                    <p className="font-medium mb-2">4. 결론</p>
                    <p className="text-gray-700">주제문을 활용한 <span className="bg-yellow-100">명확한 마무리</span></p>
                    <p className="text-gray-600 text-sm mt-1">
                      예시: "<span className="text-blue-600 font-medium">In conclusion</span>, these are why I think [주제와 연관된 결론]"
                    </p>
                  </div>
                </div>
              </div>

              {/* Tips */}
              <div>
                <h3 className="font-semibold text-lg mb-3">준비 전략</h3>
                <ul className="space-y-2">
                  <li>• <span className="bg-yellow-100">45초</span> 준비 시간 동안 <span className="underline decoration-blue-500">키워드</span>를 메모하세요</li>
                  <li>• <span className="text-blue-600 font-medium">찬성/반대 혹은 주제에 대한 나의 입장</span>을 명확히 밝히세요</li>
                  <li>• 최소 <span className="bg-yellow-100">2가지 이상의 이유</span>를 준비하세요</li>
                  <li>• 가능하다면 <span className="underline decoration-blue-500">구체적인 예시</span>를 포함하세요</li>
                </ul>
              </div>

              {/* Example */}
              <div>
                <h3 className="font-semibold text-lg mb-3">답변 예시</h3>
                <div className="space-y-4">
                  <div>
                    <p className="font-medium">Topic: "Social media has more negative effects than positive ones."</p>
                    <p className="text-gray-700 mt-2">
                      I <span className="underline decoration-blue-500">agree</span> with the statement that social media has more negative effects. 
                      I have <span className="text-blue-600 font-medium">two main reasons</span>. 
                      <span className="text-blue-600 font-medium">First</span>, social media can be addictive and waste our time. 
                      <span className="text-blue-600 font-medium">Second</span>, it often creates unrealistic expectations about life. 
                      <span className="underline decoration-blue-500">For example</span>, many people feel depressed comparing their lives to others' highlight reels. 
                      <span className="text-blue-600 font-medium">In conclusion</span>, these negative impacts outweigh the benefits of social media.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <button
        className="px-6 py-3 rounded-lg bg-[#3488fe] text-lg text-white mt-8 hover:bg-blue-600 transition-colors"
        onClick={startPractice}
      >
        연습 시작하기
      </button>
    </div>
  );
};

export default Part5Description;