import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 bg-white rounded-lg shadow-sm py-8">
        <h1 className="text-2xl font-bold mb-8 text-center">배토스 서비스 이용약관</h1>
        
        <div className="space-y-8 text-gray-600">
          {/* 제1장 총칙 */}
          <section>
            <h2 className="text-xl font-bold mb-4 text-gray-800">제1장 총칙</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">제1조(목적)</h3>
                <p className="leading-relaxed">
                  본 약관은 Bae Lab(이하 "회사")이 제공하는 토익스피킹 AI 학습 서비스 BaeTos(이하 "서비스")의 이용과 관련하여 회원과 회사 사이의 권리·의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 한다.
                </p>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">제2조(정의)</h3>
                <div className="space-y-2">
                  <p>본 약관에서 사용하는 용어는 다음과 같이 정의한다.</p>
                  <ol className="list-decimal pl-6 space-y-2">
                    <li>"웹사이트"라 함은 회사가 서비스를 제공하기 위해 운영하는 웹사이트 'https://baetos.com'를 의미한다.</li>
                    <li>"서비스"라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 BaeTos 관련 제반 서비스를 의미한다.</li>
                    <li>"회원"이라 함은 회사의 서비스에 접속하여 이 약관에 따라 회사와 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 자를 의미한다.</li>
                    <li>"아이디(ID)"라 함은 회원식별과 회원의 서비스 이용을 위해 회원이 등록한 이메일을 의미한다.</li>
                    <li>"비밀번호(Password)"라 함은 회원이 통신상의 자신의 비밀을 보호하기 위해 선정한 문자와 숫자의 조합을 의미한다.</li>
                    <li>"유료 서비스"라 함은 회원이 유료로 구매하여 이용할 수 있는 서비스를 의미한다.</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          {/* 제2장 회원의 권리와 의무 */}
          <section>
            <h2 className="text-xl font-bold mb-4 text-gray-800">제2장 회원의 권리와 의무</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">제3조(회원가입)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>이용계약은 이용신청자가 회원가입 안내에서 이 약관의 내용에 대하여 동의하고 등록절차를 통해 서비스 이용 신청을 하고, 회사가 그 신청에 대해서 승낙함으로써 체결된다.</li>
                  <li>이용신청자는 반드시 실명과 실제 정보를 사용해야 하며 한 회원은 오직 1건의 이용신청을 할 수 있다.</li>
                  <li>실명이나 실제 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용이 제한될 수 있다.</li>
                </ol>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">제4조(회원의 의무)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>회원은 다음 행위를 하여서는 안 된다:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      <li>타인의 정보도용</li>
                      <li>회사가 게시한 정보의 변경</li>
                      <li>서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제, 유통, 상업적 이용</li>
                      <li>바이러스나 악성 프로그램을 등록하거나 유포</li>
                      <li>서비스의 운영을 방해하는 행위</li>
                    </ul>
                  </li>
                  <li>회원은 관계법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항을 준수하여야 한다.</li>
                  <li>회원은 자신의 계정과 비밀번호를 관리할 책임이 있으며, 이를 제3자가 이용하도록 해서는 안 된다.</li>
                </ol>
              </div>
            </div>
          </section>

          {/* 제3장 서비스 이용 */}
          <section>
            <h2 className="text-xl font-bold mb-4 text-gray-800">제3장 서비스 이용</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">제5조(서비스의 제공)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>BaeTos는 회원에게 아래와 같은 서비스를 제공한다:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      <li>TOEIC Speaking 파트별 연습 문제 및 AI 피드백</li>
                      <li>AI 실전 모의고사 및 분석</li>
                      <li>파트별 템플릿 연습</li>
                      <li>음성 녹음 및 분석 서비스</li>
                      <li>기타 BaeTos가 개발하거나 다른 회사와의 협력을 통해 제공하는 일체의 서비스</li>
                    </ul>
                  </li>
                  <li>회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능시간을 별도로 지정할 수 있다.</li>
                  <li>서비스는 연중무휴, 1일 24시간 제공을 원칙으로 한다.</li>
                </ol>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">제6조(서비스의 변경 및 중단)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중단할 수 있다:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
                      <li>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중단했을 경우</li>
                      <li>국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</li>
                    </ul>
                  </li>
                  <li>회사는 서비스의 변경 및 중단이 있는 경우에는 그 사유 및 내용을 지체 없이 회원에게 공지한다.</li>
                </ol>
              </div>
            </div>
          </section>

          {/* 제4장 요금 및 결제 */}
          <section>
            <h2 className="text-xl font-bold mb-4 text-gray-800">제4장 요금 및 결제</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">제7조(서비스 요금)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>회사가 제공하는 서비스는 기본적으로 무료이나, 특정 서비스의 경우 유료로 제공될 수 있다.</li>
                  <li>유료서비스 이용요금의 결제방법은 다음 각 호의 방법 중 가능한 방법으로 할 수 있다:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      <li>신용카드 결제</li>
                      <li>체크카드 결제</li>
                      <li>회사가 지정하는 기타 결제방법</li>
                    </ul>
                  </li>
                </ol>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">제8조(환불규정)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>회원이 결제한 유료서비스의 환불은 다음과 같은 기준에 따른다:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      <li>구매일로부터 7일 이내에 서비스를 이용하지 않은 경우 전액 환불</li>
                      <li>모의고사를 이미 응시했거나 무제한 연습/피드백 이용권을 실제 이용한 경우 환불 불가</li>
                      <li>부분 사용된 서비스의 경우, 이용 일수와 사용량을 고려하여 환불 금액 산정</li>
                    </ul>
                  </li>
                  <li>환불 처리는 영업일 기준 3-5일이 소요될 수 있다.</li>
                </ol>
              </div>
            </div>
          </section>

          {/* 제5장 개인정보 보호 */}
          <section>
            <h2 className="text-xl font-bold mb-4 text-gray-800">제5장 개인정보 보호</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">제9조(개인정보의 보호)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>회사는 관련법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력한다.</li>
                  <li>회사는 서비스를 제공하기 위해 필요한 최소한의 개인정보만을 수집한다.</li>
                  <li>회사는 회원의 개인정보를 본인의 승낙 없이 제3자에게 제공하지 않는다.</li>
                  <li>회원의 개인정보 보호에 관한 상세한 내용은 회사의 개인정보처리방침에서 정한다.</li>
                </ol>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">제10조(음성 데이터의 수집 및 이용)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>회원이 서비스 이용 과정에서 제공하는 음성 녹음 데이터는 다음과 같은 목적으로 활용될 수 있다:
                    <ul className="list-disc pl-6 mt-2 space-y-1">
                      <li>AI 분석 및 피드백 제공</li>
                      <li>서비스 품질 향상</li>
                      <li>AI 모델 학습 및 개선</li>
                    </ul>
                  </li>
                  <li>회원의 음성 데이터는 익명화되어 처리되며, 회원의 동의 없이 제3자에게 제공되지 않는다.</li>
                  <li>회원은 자신의 음성 데이터 삭제를 요청할 수 있으며, 회사는 이를 지체 없이 처리한다.</li>
                </ol>
              </div>
            </div>
          </section>

          {/* 제6장 손해배상 및 기타사항 */}
          <section>
            <h2 className="text-xl font-bold mb-4 text-gray-800">제6장 손해배상 및 기타사항</h2>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-2">제11조(손해배상)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>회사는 서비스 이용과 관련하여 회원에게 발생한 손해 중 회사의 고의 또는 과실로 인한 손해에 대해서만 책임을 진다.</li>
                  <li>회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 발생한 분쟁에 대해서는 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없다.</li>
                </ol>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">제12조(분쟁해결)</h3>
                <ol className="list-decimal pl-6 space-y-2">
                  <li>본 약관은 대한민국 법률에 따라 규율되고 해석된다.</li>
                  <li>서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 부산지방법원을 관할 법원으로 한다.</li>
                </ol>
              </div>
            </div>
          </section>

          {/* Effective Date and Copyright */}
          <section className="mt-8 pt-8 border-t border-gray-200">
            <p className="text-sm text-gray-500">본 약관은 2025년 1월 30일부터 시행됩니다.</p>
            <p className="text-sm text-gray-500 mt-2">© 2025 Bae Lab. All Rights Reserved.</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService; 