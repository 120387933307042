import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios to make API calls
import { useAuth } from '../context/AuthProvider';
import { SparklesIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';

const Part5TemplatePractice = () => {
  const navigate = useNavigate();
  const { user } = useAuth(); // Get the authenticated user

  // State to hold progress for each section
  const [workProgress, setWorkProgress] = useState({ completed: 0, total: 0 });
  const [technologyProgress, setTechnologyProgress] = useState({ completed: 0, total: 0 });
  const [healthProgress, setHealthProgress] = useState({ completed: 0, total: 0 });
  const [travelProgress, setTravelProgress] = useState({ completed: 0, total: 0 });
  const [moneyProgress, setMoneyProgress] = useState({ completed: 0, total: 0 });
  const [learningProgress, setLearningProgress] = useState({ completed: 0, total: 0 });

  // Sections array
  const sections = [
    { 
      title: '일과 생산성 관련',
      path: '/part5-template-practice-work',
      id: 'work',
      icon: '💼',
      category: '비즈니스',
      categoryColor: 'bg-blue-100 text-blue-800'
    },
    { 
      title: '기술과 혁신 관련',
      path: '/part5-template-practice-technology',
      id: 'technology',
      icon: '💡',
      category: '기술',
      categoryColor: 'bg-purple-100 text-purple-800'
    },
    { 
      title: '건강과 웰빙 관련',
      path: '/part5-template-practice-health',
      id: 'health',
      icon: '🌿',
      category: '건강',
      categoryColor: 'bg-green-100 text-green-800'
    },
    { 
      title: '여행과 여가 관련',
      path: '/part5-template-practice-travel',
      id: 'travel',
      icon: '✈️',
      category: '여가',
      categoryColor: 'bg-orange-100 text-orange-800'
    },
    { 
      title: '돈, 저축, 투자 관련',
      path: '/part5-template-practice-money',
      id: 'money',
      icon: '💰',
      category: '재테크',
      categoryColor: 'bg-yellow-100 text-yellow-800'
    },
    { 
      title: '학습, 교육 관련',
      path: '/part5-template-practice-learning',
      id: 'learning',
      icon: '📚',
      category: '교육',
      categoryColor: 'bg-red-100 text-red-800'
    },
  ];

  // Fetch progress for each section
  useEffect(() => {
    const fetchProgress = async (fileName, setProgress) => {
      if (user) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/get-template-progress/${user.user_id}`, {
            params: { file_name: fileName }
          });
          setProgress({
            completed: response.data.completed,
            total: response.data.total,
          });
        } catch (error) {
          console.error(`Error fetching progress for ${fileName}:`, error);
        }
      }
    };

    fetchProgress('part5_template_work.json', setWorkProgress);
    fetchProgress('part5_template_technology.json', setTechnologyProgress);
    fetchProgress('part5_template_health.json', setHealthProgress);
    fetchProgress('part5_template_travel.json', setTravelProgress);
    fetchProgress('part5_template_money.json', setMoneyProgress);
    fetchProgress('part5_template_learning.json', setLearningProgress);
  }, [user]);

  const getProgress = (sectionId) => {
    switch (sectionId) {
      case 'work':
        return workProgress;
      case 'technology':
        return technologyProgress;
      case 'health':
        return healthProgress;
      case 'travel':
        return travelProgress;
      case 'money':
        return moneyProgress;
      case 'learning':
        return learningProgress;
      default:
        return { completed: 0, total: 0 };
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Navigation */}
      <div className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-sm border-b border-gray-100 z-10">
        <div className="max-w-7xl mx-auto px-4 h-16 flex items-center">
          <button
            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
            onClick={() => navigate('/')}
          >
            <ArrowLeftIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="pt-24 sm:pt-28 pb-12 px-4 sm:px-6 lg:px-8 max-w-3xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-8 sm:mb-12">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-4 tracking-tight">
            파트 5 빈출 표현 100
          </h1>
          <div className="inline-flex items-center justify-center space-x-2.5 bg-blue-50/80 px-5 py-3 rounded-2xl shadow-sm">
            <SparklesIcon className="h-5 w-5 text-blue-500" />
            <p className="text-sm sm:text-base text-gray-700 font-medium">
              실전에서 바로 활용할 수 있는 표현들을 반복적으로 연습해보세요
            </p>
          </div>
        </div>

        {/* Info Card */}
        <div className="bg-white rounded-2xl border border-gray-200/75 p-6 sm:p-8 mb-8 sm:mb-10 shadow-sm hover:shadow-md transition-all duration-300">
          <p className="text-lg text-gray-700 mb-4 leading-relaxed">
            파트 5 빈출 표현 100가지를 직접 녹음하고 피드백을 확인하며 연습해주세요.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed flex flex-wrap items-center gap-2">
            실전에서 바로 나올 수 있도록 반복적으로 연습하고
            <span className="inline-block bg-yellow-100 px-3 py-1 rounded-lg font-medium text-yellow-800">
              새로운 단어 및 표현은 외워주세요
            </span>
          </p>
        </div>

        {/* Section Cards */}
        <div className="grid gap-4 sm:gap-5">
          {sections.map((section, index) => {
            const progress = getProgress(section.id);
            return (
              <div 
                key={index} 
                className="bg-white rounded-2xl border border-gray-200/75 p-5 sm:p-7 shadow-sm hover:shadow-md transition-all duration-300"
              >
                <div className="flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6">
                  <div className="flex-1 space-y-3">
                    <div className="flex items-center gap-3 flex-wrap">
                      <span className="text-2xl">{section.icon}</span>
                      <h3 className="text-xl font-semibold text-gray-900">{section.title}</h3>
                      <span className={`text-xs px-2.5 py-1 rounded-full font-medium ${section.categoryColor}`}>
                        {section.category}
                      </span>
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="flex-1 h-2.5 bg-gray-100 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-blue-500 rounded-full transition-all duration-500 ease-out"
                          style={{ width: `${(progress.completed / progress.total) * 100}%` }}
                        />
                      </div>
                      <span className="text-sm text-gray-600 whitespace-nowrap">
                        {progress.completed}/{progress.total} 문제
                      </span>
                    </div>
                  </div>

                  <button
                    className="w-full sm:w-auto px-6 py-3 text-base rounded-xl bg-blue-500 text-white font-medium hover:bg-blue-600 active:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow-sm hover:shadow"
                    onClick={() => navigate(section.path)}
                  >
                    시작하기
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Part5TemplatePractice;